import React from "react";
import { Card, CardContent, CardTitle } from "./ui/card";
import Image from "next/image";
import useStore from "@/store/useStore";
import { cn } from "@/lib/utils";
import { Badge } from "./ui/badge";

export default function PlanCard({ selectedPlan, item, onClick, type }: any) {
  let { isToggled, plan, product } = useStore((state) => state);

  return (
    <Card
      className={cn(
        " h-15 mb-2 w-full cursor-pointer hover:border hover:border-violet-500",
        {
          "border border-solid border-blue-400": plan && plan?.Id === item.Id,
        },
      )}
      onClick={onClick}
    >
      <CardContent className="flex h-full w-full items-center gap-[14px] p-2 lg:flex-col lg:items-start lg:pt-2">
        {type && type == "giftOrder" && (
          <div className="flex w-full flex-col">
            <CardTitle className="text-c-primary-marine-blue text-base font-medium"></CardTitle>
            <div className="flex h-12 items-center justify-between">
              <div className="basis-3/7 flex flex-col items-start">
                <span className="text-lg font-bold text-primary">
                  {'"' + item?.gifted_username + '"'} kullanıcısına Hediye
                  Üyelik
                </span>
                <span className="text-xs italic">
                  1 kişi için{" "}
                  {item?.Duration &&
                  (item?.Duration == 6 || item?.Duration == 1)
                    ? item?.Duration + " ay"
                    : item?.Duration / 12 + " yıllık"}{" "}
                  üyelik
                </span>
              </div>
              <div className="basis-4/7 flex flex-col items-end">
                <div>
                  <span className="text-[18px] font-bold">
                    {`${item.Price}`} TL
                  </span>
                  <span className="text-sm text-gray-500">
                    /
                    {item?.Duration &&
                    (item?.Duration == 6 || item?.Duration == 1)
                      ? item?.Duration + " ay"
                      : item?.Duration / 12 + "yıllık"}
                  </span>
                </div>
                <span className="text-xs italic text-gray-500">1 adet</span>
              </div>
            </div>
          </div>
        )}
        {type && type == "donationOrder" && (
          <div className="flex w-full flex-col">
            <CardTitle className="text-c-primary-marine-blue text-base font-medium"></CardTitle>
            <div className="flex h-12 items-center justify-between">
              <div className="basis-3/7 flex flex-col items-start">
                <span className="text-lg font-bold text-primary">
                  Askıda Üyelik
                </span>
                <span className="text-xs italic">
                  {item?.Amount} kişi için{" "}
                  {item?.Duration &&
                  (item?.Duration == 6 || item?.Duration == 1)
                    ? item?.Duration + " aylık"
                    : item?.Duration / 12 + " yıllık"}{" "}
                  üyelik
                </span>
              </div>
              <div className="basis-4/7 flex flex-col items-end">
                <div>
                  <span className="text-[18px] font-bold">
                    {item.Price * item.Amount} TL
                  </span>
                  <span className="text-sm text-gray-500">
                    /{" "}
                    {item?.Duration &&
                    (item?.Duration == 6 || item?.Duration == 1)
                      ? item?.Duration + " ay"
                      : item?.Duration / 12 + " yıllık"}
                  </span>
                </div>
                <span className="text-xs italic text-gray-500">
                  {" "}
                  {item?.Amount} adet
                </span>
              </div>
            </div>
          </div>
        )}
        {type && (type == "basketOrder" || type == "subscriptionOrder") && (
          <div className="flex w-full flex-col">
            <CardTitle className="text-c-primary-marine-blue text-base font-medium"></CardTitle>
            <div className="flex h-12 items-center justify-between">
              <div className="basis-3/7 flex flex-col items-start">
                <span className="text-lg font-bold text-primary">
                  {item?.Title}
                </span>
                <span className="text-xs italic">
                  {item?.MarketingText1 && (
                    <Badge
                      className="bg-violet-100 text-[10px] text-violet-500"
                      variant="outline"
                    >
                      {item?.MarketingText1}
                    </Badge>
                  )}
                </span>
              </div>
              <div className="basis-4/7 flex flex-col items-end">
                <div>
                  <span className="text-[18px] font-bold">
                    {`${item.Price}`} TL
                  </span>
                  <span className="text-sm text-gray-500">
                    /{item?.Duration} Ay
                  </span>
                </div>
                <span className="text-xs italic text-gray-500 line-through">
                  {item?.MarketingText2}
                </span>
              </div>
            </div>
          </div>
        )}

        {!type && (
          <div className="flex w-full flex-col">
            <CardTitle className="text-c-primary-marine-blue text-base font-medium"></CardTitle>
            <div className="flex h-12 items-center justify-between">
              <div className="basis-3/7 flex flex-col items-start">
                <span className="text-lg font-bold text-primary">
                  {item?.Title}
                </span>
                <span className="text-xs italic">
                  {item?.MarketingText1 && (
                    <Badge
                      className="bg-violet-100 text-[10px] text-violet-500"
                      variant="outline"
                    >
                      {item?.MarketingText1}
                    </Badge>
                  )}
                </span>
              </div>
              <div className="basis-4/7 flex flex-col items-end">
                <div>
                  <span className="text-[18px] font-bold">
                    {`${item.Price}`} TL
                  </span>
                  <span className="text-sm text-gray-500">
                    /{item?.Duration} Ay
                  </span>
                </div>
                <span className="text-xs italic text-gray-500 line-through">
                  {item?.MarketingText2}
                </span>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
